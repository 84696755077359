.hero-wrapper {
  background-color: $headerBg;
  width: 100%;

  position: relative;

  .bg-logo {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: .8;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.hero {
  padding: 2.5rem;
  margin: 0 auto;
  z-index: 1;

  position: relative;


  @include desktop-large {
    padding: 10rem;
  }

  &__logo {
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      height: 100px;
      z-index: 10;

      @include tablet {
        height: 200px;
      }
      @include laptop {
        height: 130px;
      }
      @include laptop-small {
        height: 120px;
      }
      @include desktop {
        height: 150px;
      }
      @include desktop-large {
        height: 250px;
      }
    }
  }

  &__content {
    margin-top: 3rem;

    @include mobile-mini {
      margin-top: 4rem;
    }
    @include laptop {
      margin-top: 3.5rem;
    }
    @include tablet {
      margin-top: 6rem;
    }
    @include laptop-small {
      margin-top: 4rem;
    }
    @include desktop {
      margin-top: 5rem;
    }
    @include desktop-large {
      margin-top: 8rem;
    }


    h1 {
      color: white;
      font-size: 3rem;
      margin-bottom: 6rem;
      font-family: "timberline", sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: .8rem;
      line-height: 1.3;

      @include mobile-mini {
        font-size: 2.5rem;
        margin-bottom: 4rem;
        letter-spacing: .5rem;
      }
      @include tablet {
        font-size: 5rem;
      }
      @include laptop-small {
        font-size: 3rem;
      }
      @include laptop {
        font-size: 3.5rem;
        margin-bottom: 4rem;
      }
      @include desktop {
        font-size: 5rem;
        margin-bottom: 5rem;
      }
      @include desktop-large {
        font-size: 6.5rem;
        line-height: 1.5;
      }
    }
  }
  &__scroll-down{
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.shirts-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10rem 0;
  z-index: 999;
  gap: 1rem;

  @include mobile-tiny {
    gap: 2rem;
  }
  @include mobile-mini {
    gap: 3rem;
  }
  @include tablet {
    gap: 6rem;
  }
  @include laptop-small {
    gap: 10rem;
  }
  @include desktop {
    gap: 20rem;
    margin-right: -4rem;
  }

  &__shirt1,
  &__shirt3 {
    z-index: 9;
    img {
      height: 220px;
      margin-top: -5rem;

      @include mobile-tiny {
        height: 150px;
      }
      @include mobile-mini {
        height: 170px;
      }
      @include tablet {
        height: 300px;
      }
      @include laptop-small {
        height: 280px;
        margin-top: -6rem;
      }
      @include laptop {
        height: 320px;
        margin-top: -6rem;
      }
      @include desktop {
        height: 500px;
        margin-top: -8rem;
      }
    }
  }

  &__shirt2 {
    z-index: 10;

    img {
      height: 250px;
      margin-right: -2rem;

      @include mobile-tiny {
        height: 150px;
        margin-left: 0rem;
      }
      @include mobile-mini {
        height: 180px;
      }
      @include tablet {
        height: 350px;
      }
      @include laptop-small {
        height: 300px;
        margin-top: -4rem;
      }
      @include laptop {
        height: 330px;
        margin-top: -4rem;
      }
      @include desktop {
        height: 550px;
      }
    }
  }


  &__shirt1 {
    img {
      margin-right: -6rem;

      @include mobile-tiny {
        margin-right: -3rem;
      }
    }
  }
  &__shirt3 {

    img {
      margin-left: -6rem;

      @include mobile-tiny {
        margin-left: -3rem;
      }
    }
  }
}



.home {
  z-index: 1;
}

.home__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.5rem;

  @include tablet {
    padding: 5rem;
  }

  &__about {
    max-width: 1400px;
    margin: 0 auto 7rem;

    h2 {
      margin-top: 2rem;
      margin-bottom: 0rem;
      line-height: 1.2;

      @include tablet {
        margin-top: 5rem;
        margin-bottom: -2.5rem;
      }
    }
    h4 {
      margin-bottom: -2rem;
    }
  }

  &__amz {
    margin-top: -4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    span {
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: 400;

      @include tablet {
        font-size: 2.2rem;
      }
      @include desktop {
        font-size: 2.5rem;
      }
    }
    .amz-logo {
      height: 25px;
      margin-bottom: -1.1rem;
    }
    .amz-prime-logo {
      height: 50px;
      margin-bottom: -2.6rem;
      margin-right: -.5rem;
      margin-left: -.5rem;
    }
  }

  &__filters {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 8rem 0 2rem;

    @include tablet {
      margin: 8rem 0;
    }

    .search-bar {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      @include tablet {
        width: unset;
      }


      input {
        height: 61px;
        font-size: 2.5rem;
        border-radius: 1rem;
        border: 2px solid $mainRed;
        padding: 0 1.5rem;
        width: 100%;
        margin: 0 auto;
        caret-color: $mainRed;

        @include tablet {
          height: 61px;
          max-width: calc(100% - 3rem);
          min-width: 600px;
        }
      }
      input:focus {
        border-color: $mainRed;
        outline: none;
      }
      input::placeholder {
        color: grey;
      }
      .search-btn {
        display: none;

        @include tablet {
          display: initial;
        }
        margin-left: -184px;
        max-height: 61px;
      }
    }
    .magnifying-glass {
      margin-right: 2rem;
    }
    .reset-search-btn {
      display: none;
    }
    .reset-search-btn.active {
      display: unset;
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      font-size: 4rem;

      @include tablet {
        display: none;
      }
    }
  }

  &__shirts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    margin: 1rem 0rem 1rem;
  }

  .shirt-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 350px;
    height: auto;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.172);
    border-radius: 2rem;
    padding: 4rem 2.5rem;

    @include mobile-tiny {
      min-width: 290px;
      max-width: 290px;
    }
    @include tablet {
      min-width: 335px;
      max-width: 335px;
    }
    @include laptop-small {
      min-width: 460px;
      max-width: 460px;
    }

    &__amz-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 3rem;
      background-color: rgb(69, 69, 69);
      color: white;
      font-size: 2.5rem;
      border-radius: 1rem;
      width: fit-content;
      padding: 1.2rem 2rem;
      gap: 1.2rem;
      transition: .3s;

      img {
        height: 20px;

        @include mobile-tiny {
          margin-left: 1rem;
        }
      }
      span {
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: -.9rem;
        white-space: nowrap;
      }
    }
    &__amz-btn:hover {
      background-color: $mainRed;
      transform: translateY(3px);
    }

    .spinner {
      height: 354.5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
      }
      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
      }
    }


    &__shirt {
      img {
        max-width: 100%;
      }
    }

    &__title {
      font-size: 1.8rem;
      font-weight: 500;
      max-width: 380px;
      margin: 1rem auto;
      line-height: 1.2;
    }
    &__divider {
      content: "";
      height: 2px;
      width: 100%;
      background-color: rgb(225, 225, 225);
      margin: 0 auto 1.5rem;
      max-width: 300px;
    }
    &__colors {
      display: flex;
      justify-content: center;
      gap: .5rem;
      margin: 0 auto;
      max-width: 280px;
      flex-wrap: wrap;


      .colot-dot:hover {
        transform: scale(1.6);
      }
      img.color-dot{
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid black;
      }
      span {
        font-size: 1.4rem;
        margin: -.5rem 0 0;
      }
    }
  }

  .pagination {
    display: none;

    @include tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin: 7rem 0 5rem;
      font-size: 2.4rem;
      font-family: "Lato";
      font-weight: 500;
      z-index: 999;
    }

    .pagination-item {
      background-color: #ffffff00;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      line-height: 70px;
      border: 1px solid #ffffff00;
      transition: .2s;
      cursor: pointer;
    }
    .pagination-item:hover {
      border: 1px solid $mainRed;
      color: $mainText;
    }

    .pagination-item.current-page:hover {
      border: 1px solid $mainRed;
      color: white;
    }

    .current-page {
      color: white;
      background-color: $mainRed;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      line-height: 70px;
      border: 1px solid #ffffff00;
    }
  }

  .load-more-btn {
    margin-top: 2.5rem;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: rgba(41, 4, 4, 0.539) 3px 3px 10px ;

    @include tablet {
      display: none;
    }
  }

  .pagination-small {
    display: none;
    flex-direction: column;
    align-items: center;
    max-height: 40px;
    margin-bottom: 3rem;

    @include tablet {
      display: flex;
    }

    .pagination {
      margin: 0;
      font-size: 1.7rem;
      font-family: "Lato";
      font-weight: 500;
      z-index: 999;

      .pagination-item {
        background-color: #ffffff00;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ffffff00;
        transition: .2s;
        cursor: pointer;
      }
      .pagination-item:hover {
        border: 1px solid $mainRed;
        color: $mainText;
      }

      .pagination-item.current-page:hover {
        border: 1px solid $mainRed;
        color: white;
      }

      .current-page {
        color: white;
        background-color: $mainRed;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #ffffff00;
      }
    }
  }

  &__cta {
    max-width: 1400px;
    margin: 7rem auto;

    h2 {
      margin-top: 2rem;
      margin-bottom: 0rem;
      line-height: 1.2;

      @include tablet {
        margin-top: 5rem;
        margin-bottom: -2.5rem;
      }
    }
    h4 {
      margin-bottom: -2rem;
    }
    .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      @include tablet {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }


      h3 {
        display: block;
        transition: .3s;
        font-size: 3.5rem;
        line-height: 1;

        @include tablet {
          line-height: 1.3;
          font-size: 4.5rem;
        }
      }

      .envelope {
        display: flex;
        font-size: 5rem;
        transition: .3s;
        margin-top: -1rem;

        @include tablet {
          display: block;
          margin-left: 4rem;
        }
      }
    }
    .contact:hover {
      .envelope {
        color: $mainRed;
        transform: rotate(360deg) scale(1.2);
      }
    }
  }
}


.home__footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {

    img {
      height: 120px;
      margin-bottom: 2.5rem;
      transition: .8s;
    }
    img:hover {
      transform: translateY(-5px);
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin: 3rem 0;

    .facebook-icon,
    .tiktok-icon {
      color: $mainText;
      font-size: 4rem;
      transition: .3s;
    }
    .instagram-icon {
      color: $mainText;
      font-size: 5rem;
      transition: .3s;
      margin-top: -.5rem;
    }
    .facebook-icon:hover,
    .instagram-icon:hover,
    .tiktok-icon:hover {
      color: $mainRed;
      transform: scale(1.2);
      cursor: pointer;
    }

  }

  &__btt-btn {
    position: fixed;
    bottom: 0;
    right: 2rem;
    padding: .5rem 1.1rem;
    border-radius: 1rem;
    background-color: #e02d2da8;
    border: 1px solid #551111a8;
    color: white;
    margin: 1rem 0 2rem;
    cursor: pointer;
    transition: .2s;

    .back-to-top-btn {
      font-size: 1.5rem;
    }

    span {
      font-size: 1.5rem;
    }
  }
  &__btt-btn:hover {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid $mainRed;
    color: $mainRed;
  }

  &__copyrights {
    padding: 0 2.5rem 2rem;

    p {
      font-weight: 300;
      text-align: center;
      font-size: 1.5rem;

      @include desktop-large {
        font-size: 1.7rem;
      }
    }
    a {
      transition: .2s;
    }
    a:hover {
      color: green;
    }
  }
}

