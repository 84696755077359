.not-found-wrapper {
  background-color: $headerBg;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;

  .bg-logo {
    position: absolute;
    height: 100vh;
    width: 100%;
    opacity: .8;

    img {
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.not-found {
  padding: 5rem;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
  position: relative;

  @include mobile-mini {
    padding: 2.5rem;
  }
  @include desktop-large {
    padding: 10rem;
  }

  &__logo {
    display: flex;
    justify-content: center;

    img {
      height: 120px;

      @include mobile-mini {
        height: 100px;
      }
      @include tablet {
        height: 200px;
      }
      @include laptop {
        height: 130px;
      }
      @include desktop {
        height: 150px;
      }
      @include desktop-large {
        height: 250px;
      }
    }
  }

  &__content {
    margin-top: 3rem;

    @include mobile-mini {
      margin-top: 4rem;
    }
    @include laptop {
      margin-top: 3.5rem;
    }
    @include tablet {
      margin-top: 6rem;
    }
    @include desktop {
      margin-top: 5rem;
    }
    @include desktop-large {
      margin-top: 8rem;
    }

    h4 {
      color: white;
      text-align: center;
      margin-top: 8rem;

      @include mobile-mini {
        font-size: 1.5rem;
      }
      @include tablet {
        font-size: 3rem;
      }
    }

    .go-back {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &__icon {
        font-size: 4rem;
        color: $mainRed;
        margin-right: 2rem;
        transition: .4s ease-in;
      }

      span {
        display: block;
        color: white;
        text-align: center;
        font-size: 3rem;
      }
    }
    .go-back:hover {
      .go-back__icon {
        transform: translateX(-10px) rotate(-5deg);
      }
    }


    h1 {
      color: white;
      font-size: 10rem;
      margin-top: 5rem;
      margin-bottom: 6rem;
      font-family: "citrus-gothic", sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: .8rem;
      line-height: 1.3;

      @include mobile-mini {
        font-size: 8rem;
        margin-bottom: 4rem;
      }
      @include tablet {
        font-size: 10rem;
      }
      @include laptop {
        font-size: 15rem;
        margin-bottom: 4rem;
      }
      @include desktop {
        font-size: 15rem;
        margin-bottom: 5rem;
      }
      @include desktop-large {
        font-size: 25rem;
        line-height: 1.5;
      }
    }
  }
}


.copyrights {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  p {
    color: white;
    opacity: .4;
    font-weight: 300;
    text-align: center;
    font-size: 1.5rem;

    @include desktop-large {
      font-size: 1.7rem;
    }
  }
  a {
    color: white;
    transition: .2s;
  }
  a:hover {
    color: lightgreen;
    opacity: 1;
  }
}
