.welcome-wrapper {
  background-color: $headerBg;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .bg-logo {
    position: absolute;
    height: 100vh;
    width: 100%;
    opacity: .8;

    img {
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.welcome {
  padding: 5rem;

  margin: 0 auto;
  z-index: 1;
  position: relative;

  @include mobile-mini {
    padding: 2.5rem;
  }
  @include tablet {
    max-width: 1200px;
  }
  @include desktop-large {
    padding: 10rem;
  }

  &__logo {
    display: flex;
    justify-content: center;

    img {
      height: 100px;

      @include mobile-mini {
        height: 80px;
      }
      @include tablet {
        height: 200px;
      }
      @include laptop {
        height: 130px;
      }
      @include desktop {
        height: 150px;
      }
      @include desktop-large {
        height: 250px;
      }
    }
  }

  &__content {
    margin-top: 3rem;

    @include mobile-mini {
      margin-top: 3rem;
    }
    @include laptop {
      margin-top: 3.5rem;
    }
    @include tablet {
      margin-top: 6rem;
    }
    @include desktop {
      margin-top: 5rem;
    }
    @include desktop-large {
      margin-top: 8rem;
    }

    h4 {
      color: white;
      text-align: center;
      font-size: 1.7rem;

      @include mobile-mini {
        font-size: 1.5rem;
      }
      @include tablet {
        font-size: 1.8rem;
      }
    }

    h1 {
      color: white;
      font-size: 3.5rem;
      margin-bottom: 6rem;
      font-family: "citrus-gothic", sans-serif;
      font-weight: 400;
      font-style: normal;
      letter-spacing: .8rem;
      line-height: 1.3;

      @include mobile-mini {
        font-size: 2.5rem;
        margin-bottom: 4rem;
      }
      @include tablet {
        font-size: 5rem;
      }
      @include laptop {
        font-size: 3.5rem;
        margin-bottom: 4rem;
      }
      @include desktop {
        font-size: 5rem;
        margin-bottom: 5rem;
      }
      @include desktop-large {
        font-size: 6.5rem;
        line-height: 1.5;
      }
    }
  }
}

.market-btns {
  display: flex;
  justify-content: center;
  gap: 2rem;

  @include tablet {
    gap: 5rem;
  }
  @include desktop {
    gap: 10rem;
  }
  @include desktop-large {
    gap: 10rem;
  }

  &__us-btn,
  &__gb-btn  {
    cursor: pointer;
    transition: .3s;

    img {
      max-height: 220px;

      @include mobile-mini {
        max-height: 180px;
      }
      @include tablet {
        max-height: 400px;
      }
      @include laptop {
        max-height: 300px;
      }
      @include desktop {
        max-height: 400px;
      }
      @include desktop-large {
        max-height: 450px;
      }
    }
  }

  &__us-btn:hover,
  &__gb-btn:hover {
    transform: scale(1.05);
  }
}

.welcome-wrapper footer {

  .copyrights {
    width: 100%;
    padding: 5rem 2rem 0;
  }

  z-index: 5;

  p {
    color: white;
    opacity: .4;
    font-weight: 300;
    text-align: center;
    font-size: 1.5rem;

    @include desktop-large {
      font-size: 1.7rem;
    }
  }
  a {
    color: white;
    transition: .2s;
  }
  a:hover {
    color: lightgreen;
    opacity: 1;
  }
}
