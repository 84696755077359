/* Global styles */

html {
  font-size: 62.5%;
  line-height: 1.5;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden!important;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  background-color: $mainBg;
  color: $mainText;
  width: 100%!important;
  position: relative;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  overflow-x: hidden!important;
}
body.noscroll {
  overflow: auto!important;
  position: fixed;
}
a {
  color: $mainText;
  text-decoration: none;
}
h1 {
  font-size: 10rem;
  text-align: center;
  margin: 0;
}
h2 {
  font-family: "citrus-gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 6rem;
  letter-spacing: .5rem;

  @include mobile-tiny {
    font-size: 5rem;
  }
}
h3 {
  font-family: "brush-script-std", sans-serif;
  font-size: 4.5rem;
  font-weight: 400;
  font-style: normal;
}
h4 {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.3;

  @include tablet {
    font-size: 2.2rem;
  }
  @include desktop {
    font-size: 2.5rem;
  }
}
h5 {
  font-size: 1.8rem;
  font-weight: 400;
  margin: .5rem 0;

  @include desktop-large {
    font-size: 1.8rem;
  }
}

.btn-default {
  background-color: $mainRed;
  font-family: 'Lato', sans-serif;
  padding: 2rem 3.5rem;
  color: white;
  border-radius: 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: .2rem;
  border: none;
  cursor: pointer;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $scrollBar $scrollTrack;
}
*::-webkit-scrollbar {
width: 8px;
}
*::-webkit-scrollbar-track {
background: $scrollTrack;
}
*::-webkit-scrollbar-thumb {
background-color: $scrollBar;
border-radius: 20px;
}

