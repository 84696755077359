/* Walking T-shirts */
.bouncing {
	animation: bounce 2s linear infinite;
}
.bouncing-slow {
	animation: bounce 4s linear infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
}
.bouncing-rev {
	animation: bounce-rev 2s ease-in-out infinite;
}
.bouncing-rev-slow {
	animation: bounce-rev 4s ease-in-out infinite;
}
@keyframes bounce-rev {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
}
.bouncing:hover,
.bouncing-rev:hover {
  animation-play-state: paused;
}




/* Header Scroll Down Animation */
.scroll-down {
	width: 24px;

	.text {
		display: block;
		margin-top: -.8rem;
		margin-left: -53px;
		font-size: 12px;
		color: #ffffff;
		text-transform: uppercase;
		white-space: nowrap;
		opacity: .25;
		letter-spacing: .5rem;
		animation: pulse 2s linear alternate infinite;
	}

	.arrow {
		width: 28px;
		height: 8px;
		transform: scale3d(0.5, 0.5, 0.5);
		animation: move 3s ease-out infinite;
	}
	.arrow:first-child {
		animation: move 3s ease-out 1s infinite;
	}
	.arrow:nth-child(2) {
		animation: move 3s ease-out 2s infinite;
	}
	.arrow:before,
	.arrow:after {
		content: '';
		position: absolute;
		top: 4rem;
		background: #e02d2d9b;
		height: 100%;
		width: 51%;
	}
	.arrow:before {
		left: 0;
		transform: skew(0deg, 30deg);
	}
	.arrow:after {
		right: 0;
		width: 50%;
		transform: skew(0deg, -30deg);
	}
}

@keyframes pulse {
	to {
		opacity: 1;
	}
}

@keyframes move {
	25% {
		opacity: 1;
	}
	33% {
		opacity: 1;
		transform: translateY(10px);
	}
	67% {
		opacity: 1;
		transform: translateY(20px);
	}
	100% {
		opacity: 0;
		transform: translateY(30px) scale3d(0.5, 0.5, 0.5);
	}
}



/* Spinner */
.loader {
	height: 80px;
	width: 80px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid $mainRed;
  border-right: 3px solid transparent;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
