/* Headers & Welcome Page*/
$headerBg: #000;
$frontText: #fff;

/* Globals */
$mainBg: #fff;
$mainText: #000000;
$whiteText: #fff;
$mainRed: #e02d2d;

$lightBlue: #68a5db;
$blue: #4130b0;
$darkBlue: #514c9dc2;
$clear: #ffffff00;

/* Scroll Bars */
$scrollBar: #434343;
$scrollTrack: rgb(0, 0, 0);
